import { render, staticRenderFns } from "./es-CL.vue?vue&type=template&id=63c9973c&scoped=true&"
var script = {}
import style0 from "./es-CL.vue?vue&type=style&index=0&id=63c9973c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c9973c",
  null
  
)

export default component.exports